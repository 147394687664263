import { Component } from '@angular/core';
import { TokenStorageService } from './_services/token-storage.service';
import { Router, RouterModule } from '@angular/router';
import { SubscribeService } from './_services/subscribe.service';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-root',
  imports: [RouterModule, NgIf,MatIconModule, MatButtonModule],
  providers: [SubscribeService, SwUpdate],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showUsername: string;
  updateSubscription;

  readonly VAPID_PUBLIC_KEY = "BLuUCwKhcTd3F71h1EfO1UCEsaGl35HEQ1WvwMSydZygZMzJcqq05eyklFyJ_-6-MNRyEODinte7qS6iWanFyek";
  constructor(private tokenservice: TokenStorageService
    , private subscribeService: SubscribeService
    , private swPush: SwPush
    , private update: SwUpdate
    , private router: Router) {

    this.checkForUpdates();
    window.addEventListener("focus", this.play);
    this.subscribe();
  };

  play() {
    if (!this.AppisLoggedIn && this.router) { this.router.navigate(['/login']); }
  }

  public checkForUpdates(): void {
    this.updateSubscription = this.update.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          this.promptUser();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
    console.log('function checkforupdates new!');

    if (this.update.isEnabled) {
      console.log('update is enabled11');
      // Required to enable updates on Windows and ios.
      interval(30000).subscribe(() => {
        this.update.checkForUpdate().then(() => {
          console.log('checking for updates2.');
        });
      });

    } else {
      console.log('update cheching not possible');
    }

    // Important: on Safari (ios) Heroku doesn't auto redirect links to their https which allows the installation of the pwa like usual
    // but it deactivates the swUpdate. So make sure to open your pwa on safari like so: https://example.com then (install/add to home)
  }

  promptUser(): void {
    this.update.activateUpdate().then(() => {
      console.log('update found. Reload');
      window.location.reload();
    });
  }

  subscribe() {
    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
    })
      .then(sub => this.subscribeService.addSubscription(sub).subscribe())
      .catch(err => console.error("Could not subscribe to notifications", err));
  }

  public static isLoggedIn: boolean = false;
  AppisLoggedIn(): boolean {
    AppComponent.isLoggedIn = false;
    if (this.tokenservice.getToken()) {
      AppComponent.isLoggedIn = true;
      this.showUsername = this.tokenservice.getUser().username;
    }
    return AppComponent.isLoggedIn;
  }

  Signout(): void {
    this.tokenservice.signOut();
    this.router.navigate(['/login']);
  }
  title = 'shoppinglist';
}
